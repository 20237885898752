import { Injectable } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
// import { AuthNewService } from './auth-new.service';
import * as _ from 'lodash';
import { AuthNewService } from './auth-new.service';
import { Location } from '@angular/common';
// import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class CanLoadAuthGuard {


  constructor(
    private authService: AuthNewService,
    private location: Location) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {

    // return this.authNewService.isAuthenticated$.pipe(
    //   tap(loggedIn => {
    //     if (!loggedIn) {
    //       // TODO: Need to add parameter to the path as well
    //       // console.debug('login url segments', segments);
    //       let path = _.map(segments, 'path').join('/')
    //       this.authNewService.login(path);
    //     }
    //   }));
    return this.authService.isAuthenticated$.pipe(
      tap(loggedIn => {
        console.debug('canLoad loggedIn', loggedIn);
        if (!loggedIn) {
          let path = _.map(segments, 'path').join('/');
          const queryParams = this.location.path().split('?')[1]; // Get query parameters
          if (queryParams) {
            path += `?${decodeURIComponent(queryParams)}`; // Append and decode query parameters
          }
          console.debug('canLoad login path', path);
          this.authService.login(path);
        }
      }));

  }

}
